.component-calendar {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.component-calendar::after {
    display: block;
    content: '';
    clear: both;
}

.component-calendar-day{
    box-sizing: border-box;
    width: calc(100%/7);
    float: left;
    text-align: center;
    height: 3rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0 calc(100%/14 - 2.5rem/2);
}

.component-calendar-weekDay{
    width: calc(100%/7);
    float: left;
    text-align: center;
    height: 1.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    font-weight: 500;
}

.component-calendar-today{
    font-weight: 700;
}

.component-calendar-block{
    line-height: 1%;
}
