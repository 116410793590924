.component-card {
    border: 0.05rem solid rgba(112, 112, 112, 0.5);
    border-radius: 1rem;
    width: calc(100% - 1rem);
    min-height: 4.5rem;
    margin: 0.5rem;
    padding: 0;
    font-family: inherit;
    max-width: none;
    position: relative;
}

@media (min-width: 800px) {
    /* CSS that should be displayed if width is equal to or more than 1600px goes here
    padding: 0 calc((100vw -16000px)/2);*/
    .component-card {
        max-width: calc(800px - 1rem);
        margin: 0.5rem auto;
    }
}

.component-card:not(.component-card-stackable) {
    animation: component-card-rise 1s;
}

.component-card.component-card-plain {
    background: #FFFFFF;
}

.component-card.component-card-glass {
    background: rgba(239, 239, 239, 0.32);
    -webkit-backdrop-filter: blur(0.2rem);
    backdrop-filter: blur(0.2rem);
}

.component-card-header {
    display: flex;
    min-height: 4.5rem;
    align-items: center;
}

.component-card-header-title {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 0;
    margin-left: 1.25rem;
    word-break: break-word;
    margin-right: 1rem;
}

.component-card-header-left + .component-card-header-title {
    margin-left: 0;
}

.component-card-header-right {
    min-width: 2rem;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
}
.component-card-header-right:last-of-type {
    margin-right: 1.25rem;
}

.component-card-header-left {
    min-width: 4.5rem;
    min-height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.component-card-header-title > h3 {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    padding-bottom: 0.25rem;
    padding: 0;
}

.component-card-header-title > span {
    font-size: 0.875rem;
    font-weight: 300;
    margin-top: 0.25rem;
    /*color: #909090;*/
}

.component-card-lightColor > .component-card-header > .component-card-header-title >  span {
    color: #909090;

}
.component-card-darkColor > .component-card-header > .component-card-header-title >  span {
    color: #f0f0f0;

}

.component-card-body > div > .component-card:first-child {
    margin-top: 0 !important;
}

.component-card-body > div > .component-card:last-child {
    margin-bottom: 0 !important;
}

.component-card-body > div {
    padding-bottom: 0.5rem;
}

.component-card-body {
    color: black;
}

.component-card-stackable {
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;
    border-top: none;
}

.component-card-stackable:last-of-type {
    margin-bottom: 0.5rem;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

:not(.component-card-stackable) + .component-card-stackable,
.component-card-stackable:first-of-type {
    margin-top: 0.5rem;
    border-top: 0.03rem solid rgba(112, 112, 112, 0.5);
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
}

.component-card-middle {
    display: flex;
    min-height: 4.5rem;
    align-items: center;
    justify-content: center;
}

.component-card-dimmed > .component-card-header > .component-card-header-title {
    opacity: 0.5;
}

.component-card-dimmed > .component-card-header > .component-card-header-title > h3 * {
    text-decoration: line-through;
}

.component-card-header-title input {
    padding-left: 0;
}

.component-card-pinned {
    position: absolute;
    right: 0.75rem;
    top: -0.4rem;
}


@keyframes component-card-rise {
    from {
        transform: translateY(5vh);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}
