.fullPage {
    animation: component-fullPage-rise 0.5s;
}

@keyframes component-fullPage-rise {
    from {
        transform: translateY(50vh);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}
