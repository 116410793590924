.component-textField {
    align-items: center;
    max-width: none;
    display: block;
    box-sizing: content-box;

    flex-grow: 1;


    font-size: inherit;
    font-family: inherit;
    color: inherit;
}

.component-textField::placeholder {
    color: inherit;
    opacity: 50%;
}

.component-textField-default, .component-textField-search, .component-textField-email, .component-textField-password {
    height: 2rem;
    padding-left: 1rem;
    padding-right: 2rem;

    background: rgba(239, 239, 239, 0.32);
    -webkit-backdrop-filter: blur(0.2rem);
    backdrop-filter: blur(0.2rem);
    border: 0.03rem solid rgba(112, 112, 112, 0.5);
    border-radius: 0.75rem;
}

.component-textField-resizable {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    width: 100%;

    resize: none;
    overflow: hidden;
    word-break: break-word;

    background: none;
    border: none;
    backdrop-filter: none;
    height: 1em;
}

.component-textField:focus {
    outline: none;
}

.component-textField-noBackground {
    background: none;
    border: none;
    backdrop-filter: none;
    height: 1em;
}

.component-textField-search::-webkit-search-cancel-button{
  -webkit-appearance:none;
}

.component-textField-form {
    position: relative;

    align-items: center;
    display: flex;
}

.component-textField-textClear {
    display: flex;
    justify-content: center;
    align-items: center;
    position:absolute;
    right: 0.5rem;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    text-decoration: none !important;
}

.component-textField-container {
    position: relative;
}

.component-textField-space {
    display: inline-block;
    white-space: pre-wrap;
    opacity: 0;
    word-break: break-word;
}
