.component-Attribute {
    width: calc(100% - 1.5rem);
    font-family: inherit;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 0.5rem;
    margin: 1.75rem 0rem;
    max-width: none;
}

.component-Attribute-title,
.component-Attribute-right {
    min-width: 3rem;
    display: flex;
    align-items: top;
    justify-content: center;
}

.component-Attribute-title {
    margin-left: 1rem;
    margin-right: 1.5rem;
    justify-content: left;
    font-weight: 300;
    min-width: 5rem;
    align-self: center;
}

.component-Attribute-middle {
    flex-grow: 1;
    min-width: 3rem;
    display: flex;
    align-items: top;
    justify-content: right;
    text-align: right;
}

.component-Attribute-right > * {
    margin: 0;
}


.component-Attribute-right {
    margin-left: auto;
    align-self: center;
}

.component-Attribute-middle {
    align-self: center;
}

.component-Attribute + .component-card {
    margin-top: -1.4rem;
}
