.component-deleteConfirmationBanner {
 font-size: 1rem;
 color: white;
 text-align: center;
 margin-bottom: 1rem;
 white-space: pre-wrap;
 z-index: 250;
}

@media (min-width: 800px) {
    /* CSS that should be displayed if width is equal to or more than 1600px goes here
    padding: 0 calc((100vw -16000px)/2);*/
    .component-deleteConfirmationBanner {
        max-width: calc(480px - 1rem) !important;
        margin: 2rem calc(calc(100% - calc(480px - 1rem)) / 2) !important;
    }
}
