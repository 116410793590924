.component-groupDetail {
    /*animation: component-groupDetail-slide 1s;*/
}

.emptyGroupMessage {
    text-align: center;
    max-width: calc(100% - 2rem);
    margin: 1rem;
    opacity: 0.5;
    font-weight: 300;
}

@keyframes component-groupDetail-slide {
    from {
        transform: translateX(100vw);
    }

    to {
        transform: translateX(0);
    }
}
