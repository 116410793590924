.component-button {
    display: inline-flex;

    flex-direction: row;
    align-items: center;
    justify-content: center;

    min-width: 3rem;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;


    border: none;
    border-radius: 0.75rem;
    min-height: 2.5rem;
    font-family: inherit;
    font-size: 1rem;
    max-width: none;
    background: rgba(230, 230, 230, 0.64);
    -webkit-backdrop-filter: blur(0.2rem);
    backdrop-filter: blur(0.2rem);
}

@media (min-width: 800px) {
    /* CSS that should be displayed if width is equal to or more than 1600px goes here
    padding: 0 calc((100vw -16000px)/2);*/
    .component-button {
        max-width: calc(480px - 1rem) !important;
    }
    .component-button-viewSection {
        max-width: calc(360px - 1rem) !important;
        margin: 2rem  calc(calc(100% - calc(360px - 1rem)) / 2) !important;
    }
}

.component-button-viewSection {
    display: inline-flex;

    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: calc(100% - 6rem);
    margin: 2rem 3rem;

    border: none;
    border-radius: 0.75rem;
    min-height: 2.5rem;
    font-family: inherit;
    font-size: 1rem;
    max-width: none;
    background: rgba(230, 230, 230, 0.64);
    -webkit-backdrop-filter: blur(0.2rem);
    backdrop-filter: blur(0.2rem);
    animation: component-button-rise 1s;
}

.component-button>.material-symbols-rounded {
    margin: 0 -0.25rem;
}

.component-button>:not(:last-child) {
    margin-right: 0.5rem;
}

@keyframes component-button-rise {
    from {
        transform: translateY(5vh);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}
