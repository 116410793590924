.component-navBar {
    position: fixed;
    bottom: 0;

    width: calc(100% - 1rem);
    min-height: 4rem;
    display: block;
    font-family: inherit;
    padding: 0 0.5rem;
    margin: 0;

    border-top: 0.03rem solid rgba(112, 112, 112, 0.5);
    background: rgba(239, 239, 239, 0.32);
    -webkit-backdrop-filter: blur(0.2rem);
    backdrop-filter: blur(0.2rem);
}

.component-navBar > ul {
    width: 100%;
    min-height: 4rem;
    display: flex;
    flex-direction: row;
    font-family: inherit;
    align-items: center;
    padding: 0;
    margin: 0;
}

.component-navBar-tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    font-size: 0.75rem;
    opacity: 0.5;
}

.component-navBar-tab-active {
    opacity: 1;
}

.blankSpace {
    min-height: 4rem;
}

@media (min-width: 800px) {
    /* CSS that should be displayed if width is equal to or more than 1600px goes here
    padding: 0 calc((100vw -16000px)/2);*/
    .component-navBar {
        max-width: 800px;
        padding: 0 calc((100% - 800px) / 2);
    }
}
