.component-fab {
    display: inline-flex;

    flex-direction: row;
    align-items: center;
    justify-content: center;

    min-width: 3rem;
    height: 3rem;

    border: none;
    border-radius: 0.75rem;

    background-color: #FFF2DF;

    filter: drop-shadow(0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.25));

    padding: 0 1rem;

    color: black;
    font-size: 1rem;

    position: fixed;
    bottom: max(calc(var(--bottom-offset) + var(--offset)), 1rem);
    right: 1rem;
}

@media (min-width: 1200px) {
    /* CSS that should be displayed if width is equal to or more than 1600px goes here
    padding: 0 calc((100vw -16000px)/2);*/
    .component-fab {
        bottom: auto;
        top: calc(4rem + var(--offset));
        right: auto;
        left: calc(50% - 400px - 0.5rem - 3rem);
    }
    .component-fab-small {
        bottom: auto;
        top: calc(1rem + var(--offset));
    }
    .component-composedFAB-container {
        bottom: auto !important;
        top: calc(4rem + var(--offset));
        right: calc(50% + 400px + 0.5rem - 1rem) !important;
        transform-origin: top right !important;
    }
}

.component-fab-small {
    display: inline-flex;

    flex-direction: row;
    align-items: center;
    justify-content: center;

    min-width: 2.5rem;
    height: 2.5rem;

    border: none;
    border-radius: 0.75rem;


    color: black;
    font-size: 1rem;
    background-color: #FFF2DF;

    filter: drop-shadow(0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.25));

    padding: 0 0.75rem;

    position: fixed;
    bottom: calc(var(--bottom-offset) + var(--offset));
    right: 1rem;
}

.component-fab>.material-symbols-rounded,
.component-fab-small>.material-symbols-rounded {
    margin: 0 -0.25rem;
}

.component-fab>:not(:last-child),
.component-fab-small>:not(:last-child) {
    margin-right: 0.5rem;
}

.component-composedFAB-container {
    position: relative;
    position: fixed;
    bottom: max(calc(var(--bottom-offset) + var(--offset) + 1rem), 4rem);
    right: 0;
    transition: 0.3s all ease-in-out;
    transform-origin: bottom right;
}

.component-fab-blankspace {
    width: 100%;
    height: 4.5rem;
}
