.component-expandedCard {
    border: 0.03rem solid rgba(112, 112, 112, 0.5);
    border-radius: 1rem 1rem 0 0;
    width: 100%;
    min-height: 4.5rem;
    box-sizing: border-box;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    font-family: inherit;
    max-width: none;
    position: relative;
    display: flex;
    flex-direction: column;
    /*fix scrollbar behind sticky elements*/
    -webkit-transform: translateZ(0);
}

.component-expandedCard.component-expandedCard-plain {
    background: #FFFFFF;
    padding-bottom: 0.8rem;
    box-shadow: 0 -0.2rem 0.4rem rgba(0, 0, 0, 0.25);
}

.component-expandedCard.component-expandedCard-glass {
    margin: 0.5rem;
    width: auto;
    height: auto;
    background: rgba(239, 239, 239, 0.32);
    -webkit-backdrop-filter: blur(0.2rem);
    backdrop-filter: blur(0.2rem);
    border-radius: 1rem;
    box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.25);
    overflow: auto;
    max-height: 100vh;

}

.component-expandedCard-header {
    display: flex;
}

.component-expandedCard-header-title {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
    margin-left: 1.25rem;
    margin-right: 0.5rem;
}

.component-expandedCard-header-left + .component-expandedCard-header-title {
    margin-left: 0;
}

.component-expandedCard-header-right {
    margin-left: auto;
}

.component-expandedCard-header-left,
.component-expandedCard-header-right {
    min-width: 4.5rem;
    display: flex;
    justify-content: center;
    margin-top: 0.4rem;
}

.component-expandedCard-header-title > h3 {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    padding-bottom: 0.25rem;
    padding: 0;
}

.component-expandedCard-header-title > span {
    font-size: 0.875rem;
    font-weight: 400;
    margin-top: 1rem;
}

.component-expandedCard-body > .component-expandedCard {
    margin-top: 0 !important;
}

.component-expandedCard-top {
    position: sticky;
    top: 0;
    -webkit-backdrop-filter: blur(0.2rem);
    backdrop-filter: blur(0.2rem);
    opacity: 100%;
    z-index: 1;
}

.component-expandedCard-bottom {
    box-sizing: border-box;
    width: 100%;
    padding: 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 0 0 1rem 1rem;
    border-bottom: 0.03rem solid rgba(112, 112, 112, 0.5);
    box-shadow: 0rem 0.4rem 0.4rem rgba(0, 0, 0, 0.20);
    align-items: center;
}

.component-expandedCard.component-expandedCard-glass .component-expandedCard-bottom {
    box-shadow: none;
    border-bottom: none;
}

.component-expandedCard-header-title input {
    padding-left: 0;
}

@media (min-width: 800px) {
    /* CSS that should be displayed if width is equal to or more than 1600px goes here
    padding: 0 calc((100vw -16000px)/2);*/
    .component-expandedCard {
        max-width: calc(800px - 1rem);
        margin: 0.5rem auto;
        height: auto;
        border-radius: 1rem;
        box-shadow: 0rem 0rem 0.6rem rgba(0, 0, 0, 0.25) !important;
    }

    .component-expandedCard-bottom {
        border-radius: 0;
        border-bottom: none;
        box-shadow: none;
    }
}
